<template>

  <div class="cbox">
    <div class="headbar">
      <div style="float: right;">
       <el-select v-model="nYear" @change="getList">
          <el-option label="2022年" :value="2022"></el-option>
          <el-option label="2023年" :value="2023"></el-option>
          <el-option label="2024年" :value="2024"></el-option>
          <el-option label="2025年" :value="2025"></el-option>
          <el-option label="2026年" :value="2026"></el-option>
          <el-option label="2027年" :value="2027"></el-option>
       </el-select>
      </div>
      <div style="padding-top: 14px;">
        <el-breadcrumb>
          <el-breadcrumb-item>预算管理</el-breadcrumb-item>
          <el-breadcrumb-item>年度目标管理</el-breadcrumb-item>

        </el-breadcrumb>
      </div>

    </div>
    <div class="bcontent">
      <div style="color:orange">如果业务员未显示在下表，请在人员管理中把对应的人员设置为业务员角色。
        业务员年度目标会向上级汇聚，直至公司年度目标!<br />{{ nYear }}年公司全年目标营收额:{{ total }}元</div>

      <el-table :data="DataList" stripe border>
        <el-table-column type="index" label="编号" width="80">
        </el-table-column>
        <el-table-column prop="username" label="业务员姓名" min-width="120">
        </el-table-column>
        <el-table-column prop="fin_year" label="年度" min-width="120">
        </el-table-column>
        <el-table-column prop="ys_money" label="年度目标金额" min-width="120">
        </el-table-column>
        <el-table-column fixed="right" label="操作" width="150">
          <template slot-scope="scope">

            <el-button type="text" @click="update(scope.row)">编辑</el-button>
           
          </template>
        </el-table-column>
      </el-table>
    </div>

    <el-dialog title="年度目标管理" :visible.sync="dialogVisible" :close-on-click-modal="false">


      <el-form :model="EditItem" ref="EditItem" label-width="120px">

        <el-row style="padding-top: 20px;">
          <el-col :span="10">
            <el-form-item label="年度">
              <el-input v-model="nYear" readonly placeholder="年度数字"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="10">
            <el-form-item label="目标金额">
              <el-input v-model="EditItem.ys_money"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <el-row>
        <el-col :span="22" style="text-align: right;">
          <el-button type="primary" @click="saveForm">保存</el-button>
        </el-col>
      </el-row>
    </el-dialog>

  </div>
</template>

<script>
export default {
  data() {
    return {
      dialogVisible: false,
      total:0,
      nYear:new Date().getFullYear(),
      EditItem: {
        ys_money:"",
        fin_year:"",
        id: 0,
      },
      DataList: [],

    }
  },
  mounted() {
    this.getList();
  },
  methods: {
    getList() {
      this.$http.post("/api/okr_yearmoney_list", {
        page: 1
      }).then(res => {
        let total =0;
        for(let item of res.data){
          if(item.ys_money){
            total+=item.ys_money
          }
        }
        total=parseInt(total*100)/100
        this.total = total
        this.DataList = res.data
      })
    },
    update(item) {
      this.dialogVisible = true
    
      this.EditItem = item;
    },
    uploadCover(e) {

      this.EditItem.image = e.src
    },
    add() {
      this.EditItem = {
        ys_money:"",
        fin_year:"",
        id: 0,
      }
      this.dialogVisible = true
    },
    remove(id) {
      this.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$http.post("/api/mini_banner_delete", {
          id: id
        }).then(res => {
          this.$message({
            type: 'success',
            message: '删除成功'
          });

          this.getList();
        })

      }).catch(() => {

      });
    },
    saveForm() {
      if(this.EditItem){
        this.EditItem.ywy_id=this.EditItem.suid
        this.EditItem.fin_year = this.nYear
      }
      this.$http.post("/api/okr_yearmoney_set", this.EditItem).then(res => {
          this.$message({
            type: 'success',
            message: '保存成功'
          });
          this.dialogVisible = false
          this.getList();
        })
    }
  }
}
</script>

<style scoped>

</style>
